<template>
  <div>
    <clients-table-head class="mt-5 mb-5" @changeComponent="changeComponent" @changePaidFilter="paidFilter = $event"
      :paid-filter="paidFilter"></clients-table-head>
    <div>
      <v-text-field v-model="search" placeholder="Buscar clientes" color="primary" background-color="#DDDDE4"
        prepend-inner-icon="mdi-magnify" outlined hide-details></v-text-field>
    </div>

    <clients-table-item v-for="item in itemsFiltersWithPaid" :key="item._id" :item="item" @detail="detail"
      @edit="$emit('edit', $event)" @delete="$emit('delete', $event)"></clients-table-item>

    <v-progress-linear v-if="isLoading" indeterminate />

    <p v-if="itemsFiltersWithPaid.length === 0" class="text-center mt-2">
      No hay resultados
    </p>
  </div>
</template>

<script>
// Domain
import Clients from "../../domain/ClientTemporal";

export default {
  name: "ClientTemporalTable",
  mounted() {
    this.getAll();
  },
  components: {
    ClientsTableHead: () => import("./table/ClientsTableHead"),
    ClientsTableItem: () => import("./table/ClientsTableItem")
  },
  props: {
    updated: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      required: false
    }
  },
  data: () => ({
    items: [],
    search: "",
    isLoading: true,
    paidFilter: false
  }),
  methods: {
    changeComponent(component) {
      this.$emit("changeComponent", component);
    },
    async getAll() {
      try {
        this.items = await Clients.getAll('Temporal');
        this.isLoading = false;
      } catch (error) {
        console.warn(error);
      }
    },
    detail(id) {
      this.$emit("detail", id);
    }
  },
  computed: {
    itemsFilters() {
      return this.items.filter(item => {
        return (
          item._id
            .slice(-5)
            .toUpperCase()
            .includes(this.search.toUpperCase()) ||
          item.name.toUpperCase().includes(this.search.toUpperCase()) ||
          item.address.toUpperCase().includes(this.search.toUpperCase()) ||
          item.movilNumber.toString().includes(this.search)
        );
      });
    },
    itemsFiltersWithPaid() {
      if (!this.paidFilter) return this.itemsFilters;
      else return this.itemsFilters.filter(({ paid }) => paid === false);
    }
  },
  watch: {
    updated: async function () {
      if (this.updated) {
        this.items = [];
        await this.getAll();
        this.$emit("updatedComplete");
      }
    }
  }
};
</script>

<style lang="scss">
.info--number {
  width: 10%;
}

.info--name {
  width: 20%;
  padding-left: 1rem;
}

.info--phone {
  width: 15%;
}

.info--address {
  width: 40%;
}

.info--actions {
  width: 15%;
}
</style>